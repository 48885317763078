.tippy-box
    border-radius: 1rem !important
    @apply bg-hp-gradient-230 text-white font-volkswagen text-[11px] text-center

    & .tippy-content
        @apply px-[18px] py-4 max-w-[250px] text-center

.tippy-arrow
    &::before
        @apply text-hp-primary
