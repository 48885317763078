.p-skeleton
    height: 100%
    width: 100%
    background: linear-gradient(90deg, #eaeaea 25%, #f5f5f5 50%, #eaeaea 75%)
    background-size: 200% 100%
    animation: loading 1.5s infinite

@keyframes loading
    0%
        background-position: 200% 0

    100%
        background-position: -200% 0