table
    @apply shadow-hp-table
    
.table
    @apply w-full

    &__col-name
        @apply text-start rounded-b-none bg-hp-neutral-lightest pt-[22px] pb-[15px] text-black font-volkswagen text-[15px] font-extrabold leading-[33.1px]

        &:first-child
            @apply rounded-tl-[31px] pl-[27px]
        &:last-child
            @apply rounded-tr-[31px] pr-[40px]
    tr
        &:last-child .table__cell
            &:first-child
                @apply rounded-bl-[31px]
            &:last-child
                @apply rounded-br-[31px]

    &__cell
        @apply text-start h-[95px] rounded-b-none bg-hp-white font-medium text-black

    & .p-datatable-wrapper
        @apply w-full mb-[33px]