.p-paginator
    @apply flex justify-center items-center gap-3

    &-pages
        @apply flex gap-3

    &-element
        @apply text-hp-black text-center font-volkswagen text-[14px] font-medium leading-[23px] flex w-[32px] h-[32px] flex-col justify-center items-center rounded-full bg-white

    & .p-highlight
        @apply bg-hp-gradient-230 text-white
