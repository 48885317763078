/* You can add global styles to this file, and also import other style files */
@tailwind base
@tailwind components
@tailwind utilities
@import "primeicons/primeicons.css"
@import './app/shared/sass/_mixins'
@import './themes/theme'

*
    font-family: "Volkswagen Serial", "Roboto", "Arial", "sans-serif"
    margin: 0
    padding: 0
    box-sizing: border-box

/* Si quieres aplicar la fuente solamente al body */
body
    font-family: "Volkswagen Serial", "Roboto", "Arial", "sans-serif"
    color: #2F2D36

.label
    @include label-secondary

label
    @include label-secondary
