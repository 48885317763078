
.mat-mdc-text-field-wrapper
    width: 118px !important
    height: 0 !important

.mat-datepicker-popup
    border-color: #fff !important
    background: #fff !important
    border-radius: 16px

.mat-datepicker-content-container
    border-color: #fff !important
    background: #fff !important
    border-radius: 16px !important

.mat-datepicker-content
    background: #fff !important
    border-radius: 16px !important

.mat-calendar
    border-color: #fff !important
    background: #fff !important
    border-radius: 16px

.mat-calendar-body-in-range::before
    background: rgba(189, 39, 46, 0.08) !important

.mat-calendar-table-header-divider
    padding: 0 !important

.mat-calendar-body-selected
    color: #fff !important
    @apply bg-hp-gradient-230

.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
    background: rgba(189, 39, 46, 0.08) !important

.cdk-overlay-connected-position-bounding-box
    background: #00000050

    & mat-datepicker-content
        background: #fff !important
        border-color: #fff !important

    .mat-calendar-header
        background: #fff !important
        border-radius: 16px !important

    & .mat-calendar-content
        background: #fff !important
        border-radius: 16px !important

    & .mat-calendar
        background: #fff !important
        height: fit-content !important

        & .mdc-button__label
            span
                color: black !important
                font-size: 20px !important
                font-weight: 700 !important
                line-height: 24px !important

        & .mat-mdc-button-persistent-ripple::before
            background: black !important
            color: black !important

        & .mat-datepicker-content
            color: #000
        & .mat-calendar-previous-button
            color: #000 !important
            border-color: #000
        & .mat-calendar-next-button
            color: #000 !important
            border-color: #000

        & .mat-calendar-body
            .mat-calendar-body-label
                content: ""
                color: #fff !important
                padding: 0 !important
        & .mat-calendar-table-header

            th
                color: #B9B8BD !important
                font-size: 13px !important
                font-weight: 500 !important
                line-height: 18px !important
                letter-spacing: -0.195px !important

        & .mat-calendar-body-cell-content
            color: #000
            font-size: 18px !important
            font-weight: 500 !important
            line-height: 22px !important
            letter-spacing: 0.38px !important
            &:hover
                background: rgba(189, 39, 46, 0.08) !important
