@font-face
    font-family: 'Hamburger Font BF W01 Regular'
    src: url('/assets/fonts/Hamburger-Font-BF-W01-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Volkswagen Serial'
    src: url('/assets/fonts/Volkswagen-Serial-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Volkswagen Serial'
    src: url('/assets/fonts/Volkswagen-Serial-Bold.ttf') format('truetype')
    font-weight: bold
    font-style: normal
